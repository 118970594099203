import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { classNameBuilder } from 'helpers/class-name-builder'
import useForm from 'hooks/use-form'
import React from 'react'

export interface SearchInputProps {
  placeholder?: string
  className?: string
  value?: string
  onChange?: (value: string) => void
}

const SearchInput: React.FC<SearchInputProps> = ({
  className,
  placeholder,
  value,
  onChange,
}) => {
  const { getValue, setValue, submit, setValues } = useForm<{
    criteria: string
  }>({
    onSubmit: ({ criteria }) => {
      onChange?.(criteria || '')
    },
  })
  React.useEffect(() => {
    setValues({ criteria: value })
  }, [value])
  return (
    <div
      className={classNameBuilder(
        'relative flex flex-grow flex-row rounded-md shadow-sm',
        className
      )}
    >
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <MagnifyingGlassIcon
          className="h-sm w-sm text-indigo-500 text-opacity-50 sm:h-md sm:w-md"
          aria-hidden="true"
        />
      </div>
      <input
        type="search"
        className="flex min-h-lg flex-1 rounded-full border-indigo-100 pl-12 text-sm text-indigo-700 focus:border-indigo-100 focus:ring-indigo-100 sm:min-h-xl sm:rounded-md"
        value={getValue('criteria')}
        onChange={(event) => setValue('criteria', event.target.value)}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            submit()
          }
        }}
        onBlur={() => {
          if (getValue('criteria') === value) return
          submit?.()
        }}
        placeholder={placeholder}
      />
    </div>
  )
}

export default SearchInput
