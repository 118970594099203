import handleError from 'helpers/error-handler-helper'
import api from 'libs/api'

export type ParamsType = {
  email: string
  password: string
}
export type ResponseType = { accessToken: string }
type ServiceType = (params: ParamsType) => Promise<ResponseType>

const loginService: ServiceType = async ({ email, password }) => {
  try {
    const auth = { username: email, password }
    const response = await api.post('/students/auth/login', null, { auth })
    return response.data
  } catch (error) {
    const defaultMessage = 'Erro ao efetuar login'
    throw handleError<ParamsType>({
      error,
      defaultMessage,
      payload: { email, password },
    })
  }
}

export default loginService
