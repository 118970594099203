export function validEmail(email: string) {
  return !isValidEmail(email) && 'E-mail inválido'
}

function isValidEmail(email: string) {
  const regexEmailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  return regexEmailPattern.test(email)
}

export default validEmail
