import Column from 'components/atoms/column'
import Container from 'components/atoms/container'
import Row from 'components/atoms/row'
import Text from 'components/atoms/text'
import { classNameBuilder } from 'helpers/class-name-builder'
import Link from 'next/link'
import React from 'react'

type Option =
  | 'home'
  | 'courses'
  | 'team'
  | 'blog'
  | 'who-we-are'
  | 'contact'
  | 'faq'
export interface NavBarProps {
  active?: Option
}
const NavBar: React.FC<NavBarProps> = ({ active }) => {
  // TODO: SELECT CURRENT MENU BY THEIR HREF
  return (
    <Container className="hidden h-16 md:flex">
      <MenuItem
        href="/"
        isActive={active === 'home'}
        hasOpacity={!!active && active !== 'home'}
      >
        Home
      </MenuItem>
      <MenuItem
        href="/quem-somos"
        isActive={active === 'who-we-are'}
        hasOpacity={!!active && active !== 'who-we-are'}
      >
        Quem Somos
      </MenuItem>
      <MenuItem
        href="/cursos"
        isActive={active === 'courses'}
        hasOpacity={!!active && active !== 'courses'}
      >
        Cursos
      </MenuItem>
      {/* <MenuItem
        href="/corpo-docente"
        isActive={active === 'team'}
        hasOpacity={!!active && active !== 'team'}
      >
        Corpo Docente
      </MenuItem> */}
      <MenuItem href="http://blog.supremotv.com.br" hasOpacity={!!active}>
        <a target="_blank">Blog</a>
      </MenuItem>
      {/* <MenuItem
        href="/fale-conosco"
        isActive={active === 'contact'}
        hasOpacity={!!active && active !== 'contact'}
      >
        Fale Conosco
      </MenuItem> */}
      {/* <MenuItem
        href="/faq"
        isActive={active === 'faq'}
        hasOpacity={!!active && active !== 'faq'}
      >
        FAQ
      </MenuItem> */}
    </Container>
  )
}

interface MenuItemProps {
  isActive?: boolean
  hasOpacity?: boolean
  href?: string
}
const MenuItem: React.FC<MenuItemProps> = ({
  isActive,
  hasOpacity,
  href = '#',
  children,
}) => {
  return (
    <Column className="relative items-center justify-center px-md">
      <Text
        className={classNameBuilder(
          hasOpacity && 'text-opacity-50',
          'text-center text-sm font-bold uppercase text-indigo-500'
        )}
      >
        {isActive ? children : <Link href={href}>{children}</Link>}
      </Text>
      <Row
        className={classNameBuilder(
          !isActive && 'hidden',
          'absolute inset-x-0 bottom-0 h-1 bg-indigo-500'
        )}
      />
    </Column>
  )
}

export default NavBar
