import Column from 'components/atoms/column'

type LoggedMenuProps = {
  onSignOut: () => any
}

const LoggedMenu: React.FC<LoggedMenuProps> = ({ onSignOut }) => {
  const studentPortalUrl = process.env.NEXT_PUBLIC_STUDENT_PORTAL_URL
  return (
    <Column className="bg-indigo-700 py-1 text-sm capitalize text-white shadow-inner sm:rounded-md sm:bg-white sm:text-gray-700 sm:drop-shadow-lg">
      <a
        href={studentPortalUrl}
        target="_blank"
        className="block px-4 py-2  hover:bg-indigo-300 hover:text-white"
        rel="noreferrer"
      >
        Meus Cursos
      </a>
      <a
        href="/minhas-compras"
        className="block px-4 py-2  hover:bg-indigo-300 hover:text-white"
        rel="noreferrer"
      >
        Minhas Matrículas
      </a>
      <a
        onClick={onSignOut}
        className="block cursor-pointer px-4 py-2  hover:bg-indigo-300 hover:text-white"
      >
        Sair
      </a>
    </Column>
  )
}

export default LoggedMenu
