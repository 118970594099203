import Column from 'components/atoms/column'
import Row from 'components/atoms/row'
import Text from 'components/atoms/text'
import { classNameBuilder } from 'helpers/class-name-builder'
import { uniqueId } from 'lodash'
import React from 'react'
import InputMask from 'react-input-mask'

export interface TextInputProps {
  placeholder?: string
  className?: string
  inputClassName?: string
  value?: string
  id?: string
  name?: string
  label?: string
  mask?: string
  maskChar?: string
  regex?: RegExp
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void
  error?: string
  required?: boolean
  disabled?: boolean
  autoComplete?: string
}

const TextInput: React.FC<TextInputProps> = ({
  id = uniqueId('text-input-'),
  className,
  inputClassName,
  placeholder,
  value,
  label,
  name,
  mask,
  maskChar = ' ',
  regex,
  onChange,
  onBlur,
  error,
  required,
  disabled,
  autoComplete,
}) => {
  const handleChange = (
    value: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!regex || value === '' || regex.test(value)) onChange?.(value, event)
  }

  return (
    <Column className={classNameBuilder('w-full', className)}>
      <label
        htmlFor={id}
        className={classNameBuilder(
          required && 'after:ml-0.5 after:text-red-300 after:content-["*"]',
          'block text-sm font-medium text-gray-700'
        )}
      >
        {label}
      </label>
      <InputMask
        type="text"
        name={name}
        id={id}
        className={classNameBuilder(
          'mt-1 block h-xl w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
          error
            ? 'border-red-300 bg-red-50 bg-opacity-25 text-red-300 focus:border-red-300 focus:ring-red-100'
            : 'border-gray-300 focus:border-indigo-500',
          disabled && 'bg-gray-200 text-gray-500',
          inputClassName
        )}
        placeholder={placeholder}
        value={value}
        autoComplete={autoComplete}
        onChange={(event) => handleChange(event.target.value, event)}
        onBlur={(event) => onBlur?.(event)}
        disabled={disabled}
        mask={value ? mask : null} //only use the input mask if there is a value -- this prevents issues with Chrome auto-fill}
        maskChar={maskChar}
      />
      <Row className="mt-1 h-0">
        <Text className="text-xs text-red-400">{error}</Text>
      </Row>
    </Column>
  )
}

export default TextInput
