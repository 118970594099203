import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'
import Row, { RowProps } from './row'

export type CircleSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export interface CircleProps extends RowProps {
  size?: CircleSize
}

const Circle: React.FC<CircleProps> = ({
  size = 'md',
  children,
  className,
  ...props
}) => {
  return (
    <Row
      className={classNameBuilder(
        'flex-shrink-0 items-center justify-center overflow-hidden rounded-full',
        CircleSizeMap[size],
        className
      )}
      {...props}
    >
      {children}
    </Row>
  )
}

export default Circle

const CircleSizeMap: Record<CircleSize, string> = {
  xs: 'w-xs h-xs',
  sm: 'w-sm h-sm',
  md: 'w-md h-md',
  lg: 'w-lg h-lg',
  xl: 'w-xl h-xl',
}
