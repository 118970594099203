import Column from 'components/atoms/column'
import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'

export interface BodyProps {
  className?: string
}

const Body: React.FC<BodyProps> = ({ className, children }) => {
  return (
    <Column
      className={classNameBuilder(
        'flex flex-1 flex-col bg-indigo-50 bg-opacity-50',
        className
      )}
    >
      {children}
    </Column>
  )
}

export default Body
