import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'

export type TextSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export type Color = 'white'
export type FontWeight = 'bold'
export type TextOpacity = '50'

export interface TextProps {
  size?: TextSize
  color?: Color
  font?: FontWeight
  opacity?: TextOpacity
  className?: string
  onClick?: () => void
}

const Text: React.FC<TextProps> = ({
  className,
  size,
  color,
  font,
  opacity,
  children,
  onClick,
}) => {
  return (
    <span
      className={classNameBuilder(
        className,
        FontWeightMap[font],
        TextSizeMap[size],
        ColorMap[color],
        TextOpacityMap[opacity]
      )}
      onClick={onClick}
    >
      {children}
    </span>
  )
}

export default Text

const TextSizeMap: Record<TextSize, string> = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-md',
  lg: 'text-lg',
  xl: 'text-xl',
}

const ColorMap: Record<Color, string> = {
  white: 'text-white',
}

const FontWeightMap: Record<FontWeight, string> = {
  bold: 'font-bold',
}

const TextOpacityMap: Record<TextOpacity, string> = {
  '50': 'text-opacity-50',
}
