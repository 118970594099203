import { ArrowSmallLeftIcon, LockOpenIcon } from '@heroicons/react/24/solid'
import Button from 'components/atoms/button'
import Circle from 'components/atoms/circle'
import Column from 'components/atoms/column'
import Form from 'components/atoms/form'
import Row from 'components/atoms/row'
import Text from 'components/atoms/text'
import TextInput from 'components/molecules/text-input'
import { classNameBuilder } from 'helpers/class-name-builder'
import useForm from 'hooks/use-form'
import React from 'react'
import { required, validEmail } from 'validations'

export interface ForgotPasswordFormProps {
  className?: string
  onSubmit?: (values: { email: string }) => void
  onBack?: () => void
  initialValues?: { email: string }
  pending?: boolean
}
const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  className,
  onSubmit,
  onBack,
  initialValues,
  pending,
}) => {
  const { getValue, setValue, getError, submit } = useForm<{
    email: string
  }>({
    onSubmit,
    initialValues,
    validations: {
      email: [required, validEmail],
    },
  })
  return (
    <Column
      className={classNameBuilder(
        'bg-white drop-shadow-lg sm:rounded-md',
        'w-full flex-shrink-0 overflow-hidden px-md pt-lg pb-16 sm:max-w-md md:px-xl md:pt-lg',
        className
      )}
    >
      <Form onSubmit={submit} className="flex flex-col space-y-lg ">
        <Row className="flex w-full justify-between">
          <Row className="flex-shrink-0 items-center space-x-sm text-indigo-600">
            {onBack && (
              <button
                type="button"
                className="text flex h-lg w-lg items-center justify-center rounded-full bg-indigo-50 text-indigo-400 transition-all hover:drop-shadow-md active:opacity-70"
                onClick={onBack}
              >
                <ArrowSmallLeftIcon className="flex h-sm w-sm flex-shrink-0" />
              </button>
            )}
            {!onBack && (
              <Circle className="bg-indigo-100" size="lg">
                <LockOpenIcon className="h-sm w-sm " />
              </Circle>
            )}
            <Text className="text-lg">
              Redefinir <b>Senha</b>
            </Text>
          </Row>
        </Row>
        <Text className="text-sm text-gray-600">
          Uma nova senha temporária será enviada para o e-mail cadastrado.
        </Text>
        <Column className="space-y-lg pb-xs">
          <TextInput
            label="Email"
            placeholder="Insira seu e-mail"
            value={getValue('email')}
            onChange={setValue('email')}
            error={getError('email')}
            required
          />
        </Column>
        <Button type="submit" pending={pending}>
          Redefinir senha
        </Button>
      </Form>
    </Column>
  )
}

export default ForgotPasswordForm
