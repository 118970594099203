import { Popover } from '@headlessui/react'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import {
  ChevronRightIcon,
  Bars3Icon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid'
import { useLogin } from 'api-hooks/authentication/use-login'
import Circle from 'components/atoms/circle'
import Column from 'components/atoms/column'
import Container from 'components/atoms/container'
import IconButton from 'components/atoms/icon-button'
import Image from 'components/atoms/image'
import Logo from 'components/atoms/logo'
import Row from 'components/atoms/row'
import Text from 'components/atoms/text'
import SearchInput from 'components/molecules/search-input'
import { useAuthContext } from 'contexts/auth-context'
import { classNameBuilder } from 'helpers/class-name-builder'
import { useToast } from 'hooks/use-toast'
import { useQueryParams } from 'hooks/useQueryParams'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { UserType } from 'types/user-type'
import ChangePasswordModal from './login/change-password-modal'
import ForgotPasswordModal from './login/forgot-password-modal'
import LoggedMenu from './login/logged-menu'
import LoginForm from './login/login-form'
import RegisterModal from './login/register-modal'
import MobileMenu from './mobile-menu'
import Box from 'components/atoms/box'

export interface HeaderProps {}
const Header: React.FC<HeaderProps> = () => {
  const { showError } = useToast()
  const [showSignUpModal, setShowSignUpModal] = React.useState(false)
  const [showForgotPasswordModal, setShowForgotPasswordModal] =
    React.useState(false)

  const {
    user,
    signOut,
    setUserByAccessToken,
    isLoading: isLoadingUser,
  } = useAuthContext()
  const { query, updateQuery } = useQueryParams()
  const router = useRouter()
  const criteria = query?.criteria as string

  const onSearch = (criteria: string) => {
    const isSearchPage = router.pathname.includes('cursos')
    if (isSearchPage) {
      updateQuery({ criteria })
    } else {
      router.push({ pathname: '/cursos', query: { criteria } })
    }
  }

  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false)
  const toggleMobileMenu = () => setIsMobileMenuOpen((state) => !state)

  const [userToChangePassword, setUserToChangePassword] = React.useState<any>()
  const { fetch: signIn, isLoading: signingIn } = useLogin({
    onError: ({ error, message, payload }) => {
      if (error === 'required_change_password') {
        setUserToChangePassword(payload)
      } else {
        showError({ message })
      }
    },
    onSuccess: ({ accessToken }) => {
      setIsMobileMenuOpen(false)
      setUserByAccessToken(accessToken)
    },
  })

  const performSignOut = React.useCallback(() => {
    signOut()
    if (router?.pathname.includes('/minhas-compras')) {
      router.push('/')
    }
  }, [signOut])

  const [initialValues, setInitialValues] = React.useState<any>()
  const handleSignUp = () => setShowSignUpModal(true)
  const handleForgotPassword = (values) => {
    setInitialValues(values)
    setShowForgotPasswordModal(true)
  }

  return (
    <>
      <MobileMenu
        isOpen={isMobileMenuOpen}
        onClose={toggleMobileMenu}
        className="z-20"
      >
        <Popover className="relative">
          {({ open }) => {
            return (
              <>
                <Popover.Button className="mb-xs w-full">
                  <MobileProfileButton open={open} user={user} />
                </Popover.Button>
                <Popover.Panel className="absolute bottom-20 w-full">
                  {user ? (
                    <LoggedMenu onSignOut={performSignOut} />
                  ) : (
                    <LoginForm
                      onSubmit={signIn}
                      onSignUp={handleSignUp}
                      onForgetPassword={handleForgotPassword}
                      pending={signingIn}
                    />
                  )}
                </Popover.Panel>
              </>
            )
          }}
        </Popover>
      </MobileMenu>
      <Container as="header" className="flex flex-row">
        <Row className="flex-1 items-center border-gray-300 py-sm sm:border-b sm:px-0 sm:pt-lg sm:pb-md">
          <Link href={'/'}>
            <a>
              <Logo className="h-md w-min sm:mx-md" />
            </a>
          </Link>
          <Row className="hidden flex-1 items-center justify-between space-x-md md:flex">
            <SearchInput
              className="max-w-2xl"
              placeholder="Pesquise por Cursos"
              value={criteria}
              onChange={onSearch}
            />
            <Popover className="relative hidden sm:flex">
              {({ open }) => (
                <>
                  <Popover.Button>
                    <DesktopProfileButton
                      open={open}
                      user={user}
                      pending={isLoadingUser}
                    />
                  </Popover.Button>
                  <Popover.Panel className="absolute right-0 top-14 z-20">
                    {user ? (
                      <LoggedMenu onSignOut={performSignOut} />
                    ) : (
                      <LoginForm
                        onSubmit={signIn}
                        onSignUp={handleSignUp}
                        onForgetPassword={handleForgotPassword}
                        pending={signingIn}
                      />
                    )}
                  </Popover.Panel>
                </>
              )}
            </Popover>
          </Row>
          <Row className="flex-1 justify-end md:hidden" space="xs">
            <Link href="/cursos">
              <a>
                <IconButton
                  shape="round"
                  size="md"
                  icon={MagnifyingGlassIcon}
                />
              </a>
            </Link>
            <IconButton
              shape="round"
              size="md"
              icon={Bars3Icon}
              onClick={toggleMobileMenu}
            />
          </Row>
        </Row>
      </Container>
      <RegisterModal
        open={showSignUpModal}
        onClose={() => setShowSignUpModal(false)}
      />
      <ChangePasswordModal
        user={userToChangePassword}
        onClose={() => setUserToChangePassword(undefined)}
        onSuccess={() => setIsMobileMenuOpen(false)}
      />
      <ForgotPasswordModal
        open={showForgotPasswordModal}
        onClose={() => setShowForgotPasswordModal(false)}
        initialValues={initialValues}
      />
    </>
  )
}

export default Header

interface DesktopProfileButtonProps {
  open: boolean
  user: UserType
  pending?: boolean
}
const DesktopProfileButton: React.FC<DesktopProfileButtonProps> = ({
  open,
  user,
  pending,
}) => {
  if (pending)
    return (
      <Box className="h-12 w-52 animate-pulse rounded-md bg-indigo-50 bg-opacity-50" />
    )
  return (
    <Row
      className={classNameBuilder(
        open ? 'bg-indigo-500' : 'bg-indigo-50 bg-opacity-50',
        'items-center rounded-md px-sm py-xs'
      )}
      space="xs"
    >
      <Circle
        className={classNameBuilder(open ? 'bg-white' : 'bg-indigo-100')}
        size="lg"
      >
        {user?.pictureUrl ? (
          <Image src={user.pictureUrl} />
        ) : (
          <UserCircleIcon
            className={classNameBuilder('h-sm w-sm text-indigo-600')}
          />
        )}
      </Circle>
      {user ? (
        <Column
          className={classNameBuilder(
            'space-y-0.2 items-start ',
            open ? 'text-white' : 'text-indigo-500'
          )}
        >
          <Text className="text-sm font-bold ">{user.name}</Text>
          <Text className="text-xs text-opacity-50">{user.email}</Text>
        </Column>
      ) : (
        <Text
          className={classNameBuilder(
            open ? 'text-white' : 'text-indigo-500',
            'whitespace-nowrap text-xs font-bold uppercase'
          )}
        >
          Entre ou Cadastre-se
        </Text>
      )}
    </Row>
  )
}
interface MobileProfileButtonProps {
  open: boolean
  user: UserType
}
const MobileProfileButton: React.FC<MobileProfileButtonProps> = ({
  open,
  user,
}) => {
  return (
    <Row className="h-16 w-full items-center space-x-sm pl-md pr-sm active:bg-white active:bg-opacity-10">
      <Circle className="bg-white bg-opacity-50" size="lg">
        {user?.pictureUrl ? (
          <Image src={user.pictureUrl} className="h-lg w-lg" />
        ) : (
          <UserCircleIcon className="h-md w-md text-indigo-600" />
        )}
      </Circle>
      <Row className="flex-1 items-center justify-between text-white text-opacity-50">
        {user ? (
          <Column className="items-start">
            <Text className="text-sm font-bold uppercase">{user.name}</Text>
            <Text className="text-xs">{user.email}</Text>
          </Column>
        ) : (
          <Text className="text-sm font-bold uppercase">Entre ou Cadastre</Text>
        )}
        <ChevronRightIcon
          className={classNameBuilder('h-md w-md', open && '-rotate-90')}
        />
      </Row>
    </Row>
  )
}
