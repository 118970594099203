import { UserCircleIcon } from '@heroicons/react/24/outline'
import Button from 'components/atoms/button'
import Circle from 'components/atoms/circle'
import Column from 'components/atoms/column'
import Divider from 'components/atoms/divider'
import Form from 'components/atoms/form'
import Row from 'components/atoms/row'
import Text from 'components/atoms/text'
import PasswordInput from 'components/molecules/password-input'
import TextInput from 'components/molecules/text-input'
import useForm from 'hooks/use-form'
import React from 'react'
import { required, validEmail } from 'validations'

export type SignInUserDto = { email: string; password: string }
interface LoginFormProps {
  initialValues?: SignInUserDto
  onSubmit?: (user: SignInUserDto) => void
  onForgetPassword?: (values: { email: string }) => void
  onSignUp?: () => void
  pending?: boolean
}
const LoginForm: React.FC<LoginFormProps> = ({
  initialValues,
  onSubmit,
  onForgetPassword,
  onSignUp,
  pending,
}) => {
  const { getValue, setValue, getError, submit, getValues } = useForm<{
    email: string
    password: string
  }>({
    initialValues,
    onSubmit,
    validations: {
      email: [required, validEmail],
      password: [required],
    },
  })

  const handleForgotPassword = () => onForgetPassword(getValues())

  return (
    <Column className="w-full border-2 border-gray-200 border-opacity-30 bg-white drop-shadow-lg sm:w-96 sm:rounded-md">
      <Form onSubmit={submit}>
        <Column className="p-xl" space="md">
          <Row className="flex-shrink-0 items-center space-x-sm text-indigo-600">
            <Circle className="bg-indigo-100" size="lg">
              <UserCircleIcon className="h-sm w-sm" />
            </Circle>
            <Text className="text-lg">
              Já tem uma conta? <b>Entrar</b>
            </Text>
          </Row>
          <TextInput
            label="E-mail"
            placeholder="usuario@dominio.com"
            value={getValue('email')}
            error={getError('email')}
            onChange={(value) => setValue('email', value)}
          />
          <Row className="relative">
            <PasswordInput
              className="w-full"
              label="Senha"
              placeholder="- - - - -"
              value={getValue('password')}
              error={getError('password')}
              onChange={setValue('password')}
            />
            <Text
              className="absolute top-0 right-0 cursor-pointer text-sm text-indigo-500 hover:text-blue-400"
              onClick={handleForgotPassword}
            >
              Esqueci minha senha
            </Text>
          </Row>
          <Button type="submit" shape="round" pending={pending}>
            Acessar
          </Button>
        </Column>
        <Divider />
        <Row className="items-center justify-center py-md text-xs uppercase text-indigo-500">
          <Text>
            É novo usuário?{' '}
            <b onClick={onSignUp} className="cursor-pointer">
              <Text>Criar uma conta</Text>
            </b>
          </Text>
        </Row>
      </Form>
    </Column>
  )
}

export default LoginForm
