import handleError from 'helpers/error-handler-helper'
import api from 'libs/api'
import { StudentType } from 'types/student-type'
import { SuccessType } from 'types/success-type'

export type ParamsType = { student: StudentType }
export type ResponseType = SuccessType<StudentType>

type ServiceType = (params: ParamsType) => Promise<ResponseType>

const CreateStudentService: ServiceType = async ({ student }) => {
  try {
    const { data } = await api.post<StudentType>(
      '/students/auth/signup',
      student
    )
    const message = 'Aluno criado com sucesso'
    return { data, message }
  } catch (error) {
    const defaultMessage = 'Erro ao criar aluno'
    throw handleError({ error, defaultMessage })
  }
}

export default CreateStudentService
