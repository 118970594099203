import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'

type SpaceType = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export interface ColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  space?: SpaceType
  className?: string
}

const Column = React.forwardRef<HTMLInputElement, ColumnProps>(
  ({ space, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={classNameBuilder(
          'flex flex-col',
          spaceClassesMap[space],
          className
        )}
        {...props}
      ></div>
    )
  }
)

export default Column

const spaceClassesMap: Record<SpaceType, string> = {
  xs: 'space-y-xs',
  sm: 'space-y-sm',
  md: 'space-y-md',
  lg: 'space-y-lg',
  xl: 'space-y-xl',
}
