import { useMutation } from 'react-query'
import createStudentService, {
  ParamsType,
  ResponseType,
} from 'services/student/create-student-service'
import { MutateHookType } from 'types/mutate-hook-type'

type HookType = MutateHookType<ParamsType, ResponseType>

export const useCreateStudent: HookType = (options) => {
  const mutation = useMutation(createStudentService, options)
  const { isLoading, mutate: fetch, error, data } = mutation
  return { data, error, isLoading, fetch }
}
