import { XMarkIcon } from '@heroicons/react/24/solid'
import { useChangePassword } from 'api-hooks/authentication/use-change-password'
import Column from 'components/atoms/column'
import Modal from 'components/atoms/modal'
import { useAuthContext } from 'contexts/auth-context'
import { useToast } from 'hooks/use-toast'
import React from 'react'
import { UserType } from 'types/user-type'
import ChangePasswordForm from './change-password-form'

export interface RegisterModalProps {
  user?: UserType
  onClose?: () => void
  onSuccess?: () => void
  className?: string
}

const ChangePasswordModal: React.FC<RegisterModalProps> = ({
  user,
  onClose,
  className,
  onSuccess,
}) => {
  const { setUserByAccessToken } = useAuthContext()
  const { showError, showSuccess } = useToast()
  const { fetch: changePassword, isLoading } = useChangePassword({
    onError: showError,
    onSuccess: ({ message, data }) => {
      showSuccess({ message })
      setUserByAccessToken(data.accessToken)
      onSuccess?.()
      onClose?.()
    },
  })

  return (
    <Modal
      open={!!user}
      onClose={onClose}
      className="flex flex-grow sm:flex-grow-0"
    >
      <Column className="relative flex-grow sm:flex-grow-0">
        <button
          className="absolute top-xs right-xs z-10 flex h-lg w-lg items-center justify-center rounded-full text-indigo-400 transition-all hover:text-red-400 active:opacity-70 sm:-top-sm sm:-right-sm sm:bg-indigo-50 sm:hover:drop-shadow-md"
          onClick={onClose}
        >
          <XMarkIcon className="flex h-sm w-sm flex-shrink-0" />
        </button>
        <ChangePasswordForm
          className={className}
          onSubmit={changePassword}
          pending={isLoading}
          initialValues={user as any}
        />
      </Column>
    </Modal>
  )
}

export default ChangePasswordModal
