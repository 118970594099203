import { XMarkIcon } from '@heroicons/react/24/solid'
import { useCreateStudent } from 'api-hooks/student/useCreateStudent'
import Column from 'components/atoms/column'
import Modal from 'components/atoms/modal'
import { useToast } from 'hooks/use-toast'
import React from 'react'
import RegisterForm from './register-form'

export interface RegisterModalProps {
  open?: boolean
  onClose?: () => void
  className?: string
}

const RegisterModal: React.FC<RegisterModalProps> = ({
  open,
  onClose,
  className,
}) => {
  // console.log({ open })
  const { showError, showSuccess } = useToast()
  const { fetch: createStudent, isLoading } = useCreateStudent({
    onError: showError,
    onSuccess: ({ message }) => {
      showSuccess({ message })
      onClose?.()
    },
  })

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="flex flex-grow sm:flex-grow-0"
    >
      <Column className="relative flex-grow sm:flex-grow-0">
        <button
          className="absolute top-xs right-xs z-10 flex h-lg w-lg items-center justify-center rounded-full text-indigo-400 transition-all hover:text-red-400 active:opacity-70 sm:-top-sm sm:-right-sm sm:bg-indigo-50 sm:hover:drop-shadow-md"
          onClick={onClose}
        >
          <XMarkIcon className="flex h-sm w-sm flex-shrink-0" />
        </button>
        <RegisterForm
          className={className}
          onSubmit={(student) => createStudent({ student })}
          pending={isLoading}
        />
      </Column>
    </Modal>
  )
}

export default RegisterModal
