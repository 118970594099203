import handleError from 'helpers/error-handler-helper'
import api from 'libs/api'
import { SuccessType } from 'types/success-type'

export type ParamsType = {
  email: string
  password: string
  newPassword: string
}
export type ResponseType = SuccessType<{ accessToken: string }>
type ServiceType = (params: ParamsType) => Promise<ResponseType>

const changePasswordService: ServiceType = async ({
  email,
  password,
  newPassword,
}) => {
  try {
    const { data } = await api.post('/students/auth/change-password', null, {
      auth: { username: email, password: `${password}:${newPassword}` },
    })
    const message = 'Senha atualizada com sucesso'
    return { message, data }
  } catch (error) {
    const defaultMessage = 'Erro ao atualizar senha'
    throw handleError({ error, defaultMessage })
  }
}

export default changePasswordService
