import Row from 'components/atoms/row'
import Text from 'components/atoms/text'
import { classNameBuilder } from 'helpers/class-name-builder'
import { uniqueId } from 'lodash'
import React from 'react'

export interface PasswordInputProps {
  placeholder?: string
  className?: string
  value?: string
  id?: string
  name?: string
  label?: string
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
  autoComplete?: string
  required?: boolean
  disabled?: boolean
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  id = uniqueId('password-input-'),
  className,
  placeholder,
  value,
  label,
  name,
  onChange,
  error,
  autoComplete,
  required,
  disabled,
}) => {
  return (
    <div className={className}>
      <label
        htmlFor={id}
        className={classNameBuilder(
          'block text-sm font-medium text-gray-700',
          required && 'after:ml-0.5 after:text-red-300 after:content-["*"]',
          disabled && 'bg-gray-200 text-gray-500'
        )}
      >
        {label}
      </label>
      <div className={classNameBuilder(label && 'mt-1')}>
        <input
          type="password"
          name={name}
          id={id}
          className={classNameBuilder(
            'block w-full rounded-md  shadow-sm focus:ring-indigo-500 sm:text-sm',
            error
              ? 'border-red-300 bg-red-50 bg-opacity-25 text-red-300 focus:border-red-300 focus:ring-red-100'
              : 'border-gray-300 focus:border-indigo-500'
          )}
          placeholder={placeholder}
          value={value}
          autoComplete={autoComplete}
          onChange={(event) => onChange?.(event.target.value, event)}
        />
        <Row className="mt-1 h-0">
          <Text className="text-xs text-red-400">{error}</Text>
        </Row>
      </div>
    </div>
  )
}

export default PasswordInput
