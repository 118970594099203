import Column from 'components/atoms/column'
import Head from 'next/head'
import React from 'react'

export interface WebpageProps {
  title?: string
  className?: string
}

const Webpage: React.FC<WebpageProps> = ({
  title = 'Superus',
  className,
  children,
}) => {
  return (
    <Column className={className ?? 'flex min-h-screen flex-1 flex-col'}>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.svg" />
      </Head>
      {children}
    </Column>
  )
}

export default Webpage
