import { XMarkIcon } from '@heroicons/react/24/solid'
import Column from 'components/atoms/column'
import Logo from 'components/atoms/logo'
import Row from 'components/atoms/row'
import { classNameBuilder } from 'helpers/class-name-builder'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

export interface MobileMenuProps {
  className?: string
  isOpen?: boolean
  onClose?: () => void
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  isOpen,
  onClose,
  children,
  className,
}) => {
  if (!isOpen) return null
  return (
    <Column
      className={classNameBuilder(
        'fixed inset-0 overflow-hidden bg-indigo-600 sm:hidden',
        className
      )}
    >
      <Row className="mr-sm h-16 items-center justify-between pl-md">
        <Logo className="h-sm" variant="on-dark" />
        <CloseButton onClick={onClose} />
      </Row>
      <MenuList className="mt-xs flex-1">
        <MenuItem href="/">Home</MenuItem>
        <MenuItem href={'/quem-somos'}>Quem somos</MenuItem>
        <MenuItem href={'/cursos'}>Cursos</MenuItem>
        {/* <MenuItem href={'/corpo-docente'}>Corpo Docente</MenuItem> */}
        <MenuItem href={'http://blog.supremotv.com.br'} target="_blank">
          Blog
        </MenuItem>
      </MenuList>
      {children}
    </Column>
  )
}

export default MobileMenu

interface MenuListProps {
  className?: string
}
const MenuList: React.FC<MenuListProps> = ({ className, children }) => {
  return (
    <ul className={classNameBuilder('flex flex-1 flex-col', className)}>
      {children}
    </ul>
  )
}

interface MenuItemProps {
  href: string
  target?: HTMLAnchorElement['target']
}
const MenuItem: React.FC<MenuItemProps> = ({ href, children, target }) => {
  const { asPath } = useRouter()
  const isActive = asPath === href
  return (
    <Link href={href}>
      <a target={target}>
        <li
          className={classNameBuilder(
            'flex h-10 items-center border-l-4 border-transparent px-md text-sm font-bold uppercase text-white transition-all',
            isActive ? 'border-white ' : 'text-opacity-50'
          )}
        >
          {children}
        </li>
      </a>
    </Link>
  )
}

interface CloseButtonProps {
  onClick?: () => void
}
const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <button
      className="flex h-lg w-lg items-center justify-center rounded-full bg-indigo-50 bg-opacity-10 "
      onClick={onClick}
    >
      <XMarkIcon className="h-sm w-sm text-white" />
    </button>
  )
}
