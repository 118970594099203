import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'

export type IconButtonShape = 'round' | 'rounded'
export type IconButtonSize = 'md'
export type IconButtonIntent = 'secondary' | 'ghost' | 'white'

export interface IconButtonProps {
  shape?: IconButtonShape
  size?: IconButtonSize
  icon: React.FC<React.ComponentProps<'svg'>>
  intent?: IconButtonIntent
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
}

const IconButton: React.FC<IconButtonProps> = ({
  size = 'md',
  shape = 'round',
  icon: Icon,
  intent = 'secondary',
  onClick,
  className,
}) => {
  return (
    <button
      type="button"
      className={classNameBuilder(
        'inline-flex flex-shrink-0 items-center justify-center overflow-hidden rounded-full border border-transparent p-3 shadow-sm',
        ButtonIntentMap[intent],
        ButtonSizeMap[size],
        ButtonShapeMap[shape],
        className
      )}
      onClick={onClick}
    >
      <Icon
        className={classNameBuilder('flex flex-shrink-0', IconSizeMap[size])}
      />
    </button>
  )
}
const ButtonIntentMap: Record<IconButtonIntent, string> = {
  secondary: `text-indigo-300 bg-indigo-50 bg-opacity-50 hover:bg-opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`,
  ghost: `text-indigo-300 hover:bg-indigo-50 hover:bg-opacity-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`,
  white: `text-indigo-300 hover:bg-indigo-50 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border border-gray-400`,
}
const ButtonSizeMap: Record<IconButtonSize, string> = {
  md: 'w-lg h-lg',
}
const ButtonShapeMap: Record<IconButtonShape, string> = {
  round: 'rounded-full',
  rounded: 'rounded-md',
}

const IconSizeMap: Record<IconButtonSize, string> = {
  md: 'w-sm h-sm',
}

export default IconButton
