import { LockOpenIcon } from '@heroicons/react/24/solid'
import Button from 'components/atoms/button'
import Circle from 'components/atoms/circle'
import Column from 'components/atoms/column'
import Form from 'components/atoms/form'
import Row from 'components/atoms/row'
import Text from 'components/atoms/text'
import PasswordInput from 'components/molecules/password-input'
import TextInput from 'components/molecules/text-input'
import { classNameBuilder } from 'helpers/class-name-builder'
import useForm from 'hooks/use-form'
import React from 'react'
import required from 'validations/required'

export interface ChangePasswordFormProps {
  initialValues?: {
    email: string
    password: string
    newPassword?: string
    confirmPassword?: string
  }
  className?: string
  onSubmit?: (values: {
    email: string
    password: string
    newPassword: string
  }) => void
  pending?: boolean
}
const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  className,
  onSubmit,
  pending,
  initialValues,
}) => {
  const { getValue, setValue, getError, submit } = useForm<{
    email: string
    password: string
    newPassword: string
    confirmPassword: string
  }>({
    initialValues,
    onSubmit,
    validations: {
      newPassword: [required],
      confirmPassword: [
        required,
        (value) =>
          value !== getValue('newPassword') ? 'senhas diferentes' : null,
      ],
    },
  })
  return (
    <Column
      className={classNameBuilder(
        'bg-white drop-shadow-lg sm:rounded-md',
        'w-full overflow-hidden px-md pt-lg pb-16 sm:max-w-md md:px-xl md:pt-lg',
        className
      )}
    >
      <Form onSubmit={submit} className="flex flex-col  space-y-lg ">
        <Row className="flex  w-full justify-between">
          <Row className="w- flex-shrink-0 items-center space-x-sm text-indigo-600">
            <Circle className="bg-indigo-100" size="lg">
              <LockOpenIcon className="h-sm w-sm " />
            </Circle>
            <Text className="text-lg">
              Cadastrar nova <b>senha</b>
            </Text>
          </Row>
        </Row>
        <Column className="space-y-lg pb-xs">
          <TextInput label="Email" value={getValue('email')} disabled />
          <PasswordInput
            label="Nova Senha"
            placeholder="Insira sua senha"
            value={getValue('newPassword')}
            onChange={setValue('newPassword')}
            error={getError('newPassword')}
            required
          />
          <PasswordInput
            label="Repetir Senha"
            placeholder="Confirme sua senha"
            value={getValue('confirmPassword')}
            onChange={setValue('confirmPassword')}
            error={getError('confirmPassword')}
            required
          />
        </Column>
        <Button type="submit" pending={pending}>
          Cadastrar senha
        </Button>
      </Form>
    </Column>
  )
}

export default ChangePasswordForm
