import React from 'react'

export interface HeadingProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  className?: string
}

const Heading: React.FC<HeadingProps> = ({
  as = 'h2',
  className,
  children,
}) => {
  const DynamicTag = `${as}` as keyof JSX.IntrinsicElements
  return <DynamicTag className={className}>{children}</DynamicTag>
}

export default Heading
