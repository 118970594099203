import handleError from 'helpers/error-handler-helper'
import api from 'libs/api'
import { SuccessType } from 'types/success-type'

export type ParamsType = { email: string }
export type ResponseType = SuccessType<void>
type ServiceType = (params: ParamsType) => Promise<ResponseType>

const forgotPasswordService: ServiceType = async ({ email }) => {
  try {
    const { data } = await api.post('/students/auth/forgot-password', { email })
    const message = 'Senha temporária enviada para o e-mail cadastrado'
    return { message, data }
  } catch (error) {
    const defaultMessage = 'Erro ao redefinir senha'
    throw handleError({ error, defaultMessage })
  }
}

export default forgotPasswordService
