import { IdentificationIcon } from '@heroicons/react/24/outline'
import { ArrowSmallLeftIcon } from '@heroicons/react/24/solid'
import Button from 'components/atoms/button'
import Circle from 'components/atoms/circle'
import Column from 'components/atoms/column'
import Form from 'components/atoms/form'
import Heading from 'components/atoms/heading'
import Row from 'components/atoms/row'
import TextInput from 'components/molecules/text-input'
import { classNameBuilder } from 'helpers/class-name-builder'
import useForm from 'hooks/use-form'
import React from 'react'
import { StudentType } from 'types/student-type'
import { required, validCpf, validEmail } from 'validations'

export interface RegisterFormProps {
  className?: string
  onSubmit?: (student: StudentType) => void
  initialValues?: StudentType
  onBack?: () => void
  pending?: boolean
}
const RegisterForm: React.FC<RegisterFormProps> = ({
  className,
  onSubmit,
  initialValues,
  onBack,
  pending,
}) => {
  const { getValue, setValue, getError, submit } = useForm<StudentType>({
    onSubmit,
    initialValues,
    validations: {
      name: [required],
      document: [required, validCpf],
      email: [required, validEmail],
    },
  })
  return (
    <Column
      className={classNameBuilder(
        'bg-white drop-shadow-lg sm:rounded-md',
        'w-full max-w-[720px] overflow-hidden px-md pt-md pb-16 md:px-xl md:pt-lg',
        className
      )}
    >
      <Form onSubmit={submit} className="flex w-full flex-col space-y-lg ">
        <Row className="flex w-full justify-between">
          <Row className="flex-shrink-0 items-center space-x-sm text-indigo-600">
            {onBack && (
              <button
                type="button"
                className="text flex h-lg w-lg items-center justify-center rounded-full bg-indigo-50 text-indigo-400 transition-all hover:drop-shadow-md active:opacity-70"
                onClick={onBack}
              >
                <ArrowSmallLeftIcon className="flex h-sm w-sm flex-shrink-0" />
              </button>
            )}
            {!onBack && (
              <Circle className="bg-indigo-100" size="lg">
                <IdentificationIcon className="h-sm w-sm " />
              </Circle>
            )}
            <Heading as="h3" className="text-lg">
              É novo usuário? <b>Criar uma Conta</b>
            </Heading>
          </Row>
        </Row>

        <Column className="space-y-lg">
          <TextInput
            label="Nome"
            placeholder="Nome completo do usuário"
            value={getValue('name')}
            onChange={(value) => setValue('name', value)}
            required
            error={getError('name')}
          />
          <TextInput
            label="Nome Curto"
            placeholder="Nome que prefere ser chamado"
            value={getValue('shortName')}
            onChange={(value) => setValue('shortName', value)}
          />
          <TextInput
            label="E-mail"
            placeholder="email@dominio.com"
            value={getValue('email')}
            onChange={(value) => setValue('email', value.toLowerCase())}
            required
            error={getError('email')}
          />
          <TextInput
            label="CPF"
            placeholder="000.000.000-00"
            mask="999.999.999-99"
            value={getValue('document')}
            required
            onChange={(value) => setValue('document', value)}
            error={getError('document')}
          />
          {/* <PasswordInput label="Senha" placeholder="- - - -" />
            <PasswordInput label="Confirmar Senha" placeholder="- - - -" />
            <TextInput label="CEP" placeholder="00.000-000" />
            <TextInput label="Endereço" placeholder="Nome da rua" />
            <Row className="gap-lg">
              <TextInput label="Número" placeholder="000" />
              <TextInput label="Complemento" placeholder="apto 2" />
            </Row>
            <SelectInput label="Estado" />
            <SelectInput label="Cidade" />
            <SelectInput label="Bairro" /> */}
        </Column>
        <Button type="submit" pending={pending}>
          criar conta
        </Button>
      </Form>
    </Column>
  )
}

export default RegisterForm
