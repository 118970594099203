import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { createPortal } from 'react-dom'

export interface ModalProps {
  className?: string
  open?: boolean
  onClose: () => void
  initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined
}

const Modal: React.FC<ModalProps> = ({
  className,
  open,
  onClose,
  children,
  initialFocus,
}) => {
  if (typeof document === 'undefined') return null

  return createPortal(
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-auto"
        onClose={onClose}
        initialFocus={initialFocus}
      >
        <div className="flex min-h-screen items-end justify-center filter-none sm:items-center sm:p-0 ">
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#171633] bg-opacity-70 backdrop-blur-md transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0 lg:scale-95"
            enterTo="opacity-100 lg:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 lg:scale-100"
            leaveTo="opacity-0 lg:scale-95"
            className={className}
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>,
    document.getElementById('portal')
  )
}

export default Modal
