import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'

export type Orientation = 'line' | 'col'
export interface DividerProps {
  orientation?: Orientation
  className?: string
}

const Divider: React.FC<DividerProps> = ({
  orientation = 'line',
  className,
}) => {
  return (
    <div
      className={classNameBuilder(
        className,
        'flex flex-1 border-indigo-50',
        OrientationMap[orientation]
      )}
    />
  )
}

const OrientationMap: Record<Orientation, string> = {
  line: 'border-t',
  col: 'border-r',
}

export default Divider
