import React from 'react'

type LogoVariantType = 'on-light' | 'on-dark'
export interface LogoProps extends React.SVGProps<SVGSVGElement> {
  variant?: LogoVariantType
}

const Logo: React.FC<LogoProps> = ({ variant = 'on-light', ...props }) => {
  const logoMap: Record<
    LogoVariantType,
    React.FC<React.SVGProps<SVGSVGElement>>
  > = {
    'on-light': LogoOnLight,
    'on-dark': LogoOnDark,
  }
  const MyLogo = logoMap[variant]

  return <MyLogo {...props} />
}

export default Logo

const LogoOnDark: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 133 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M113.266 0H120V9.79788C120 9.79788 113.202 13.4362 109.5 10.117V3.76596C109.5 1.69149 111.191 0 113.266 0Z"
        fill="white"
      />
      <path
        d="M129 0H122.266V9.79788C122.266 9.79788 129.064 13.4362 132.766 10.117V3.76596C132.766 1.69149 131.075 0 129 0Z"
        fill="white"
      />
      <path
        d="M129 23.8723H122.266V12.351C122.266 12.351 127.915 15.1276 132.766 11.234V20.1063C132.766 22.1808 131.075 23.8723 129 23.8723Z"
        fill="white"
      />
      <path
        d="M113.266 23.8723H120V12.351C120 12.351 114.351 15.1276 109.5 11.234V20.1063C109.5 22.1808 111.191 23.8723 113.266 23.8723Z"
        fill="white"
      />
      <path
        d="M13.3085 4.49997C12.3511 5.07444 11.1383 4.94678 10.2766 4.21274C9.54255 3.63827 8.80851 3.38295 7.78723 3.38295C5.64894 3.38295 4.53192 4.65955 4.53192 6.12763C4.53192 6.82976 4.78723 8.01061 7.02128 8.90423L9.31915 9.82976C13.5638 11.5532 14.7447 13.9787 14.7447 16.5957C14.7447 20.9681 11.6489 23.9681 7.34043 23.9681C4.69149 23.9681 3.09574 22.9787 1.91489 21.6702C0.670213 20.2978 0.12766 18.7659 0 17.2021L0.12766 17.1702C1.7234 16.8191 3.31915 17.5851 4.18085 18.9893C4.2766 19.117 4.34043 19.2446 4.43617 19.3723C5.10638 20.2021 6.06383 20.7446 7.43617 20.7446C9.57447 20.7446 11.234 19.1808 11.234 16.8829C11.234 14.5532 9.44681 13.5638 7.91489 12.9574L5.71277 12.0319C3.82979 11.2659 1.02128 9.67018 1.02128 6.22337C1.02128 3.12763 3.44681 0.159546 7.75532 0.159546C10.2447 0.159546 11.6489 1.08508 12.383 1.72338C12.9894 2.26593 13.6596 3.0638 14.1383 4.02125L13.3085 4.49997Z"
        fill="white"
      />
      <path
        d="M16.4361 6.22339C17.9042 6.22339 19.117 7.40424 19.117 8.90424V17.1064C19.117 18.4149 19.5638 19.2447 19.9468 19.6915C20.4255 20.2979 21.351 20.8723 22.6915 20.8723C24.0319 20.8723 24.9574 20.2659 25.4361 19.6915C25.8191 19.2447 26.2659 18.4468 26.2659 17.1064V8.90424C26.2659 7.43615 27.4468 6.22339 28.9468 6.22339H29.6489V16.8191C29.6489 18.4468 29.3936 20.1064 28.3404 21.4468C27.0957 23.1064 24.9255 23.9362 22.6915 23.9362C20.4574 23.9362 18.2872 23.0745 17.0425 21.4468C15.9893 20.1383 15.734 18.4468 15.734 16.8191V6.22339H16.4361Z"
        fill="white"
      />
      <path
        d="M36.3829 6.22339C38.3297 6.22339 39.6701 6.44679 40.9467 7.5319C42.351 8.77658 42.702 10.4681 42.702 11.7128C42.702 12.383 42.6063 14.2659 41.2659 15.5745C40.3084 16.5319 38.8722 17.1064 36.8616 17.1064H34.6914V20.9362C34.6914 22.3723 33.5425 23.5213 32.1063 23.5213H31.3403V6.22339H36.3829ZM34.7233 13.2766C34.7233 13.8191 35.1382 14.234 35.6808 14.234H36.0957C36.7339 14.234 37.5637 14.234 38.2978 13.6915C38.9361 13.2128 39.2233 12.4149 39.2233 11.6808C39.2233 10.7234 38.7446 10.0851 38.2659 9.70211C37.5637 9.22339 36.6701 9.15956 35.8722 9.15956H35.6488C35.1063 9.15956 34.6914 9.57445 34.6914 10.117V13.2766H34.7233Z"
        fill="white"
      />
      <path
        d="M48.7979 6.22339C51.1916 6.22339 52.4043 6.86169 53.2022 7.5319C54.5107 8.64892 54.9256 10.117 54.9256 11.4255C54.9256 13.117 54.2554 14.5851 52.915 15.5106C52.4682 15.8298 51.8618 16.117 51.032 16.2766L56.4256 23.5851H54.7979C53.2022 23.5851 51.8299 22.3404 50.9362 21L47.8405 16.9787C47.7128 16.7553 47.4575 16.6276 47.2022 16.6276V23.5851H43.8192V6.22339H48.7979ZM47.1703 13.2447C47.1703 13.7872 47.5852 14.2021 48.1277 14.2021C48.766 14.2021 51.4469 14.1383 51.4469 11.5851C51.4469 8.99998 48.7979 8.99998 48.1596 8.99998H48.0958C47.5533 8.99998 47.1384 9.41488 47.1384 9.95743V13.2447H47.1703Z"
        fill="white"
      />
      <path
        d="M64.3402 9.15956H60.3189V13.0213H64.7231V15.9255H60.3189V20.6489H64.3402C65.5529 20.6489 66.5423 21.6383 66.5423 22.851V23.5532H56.9359V6.22339H66.5423V6.92552C66.5104 8.1702 65.5529 9.15956 64.3402 9.15956Z"
        fill="white"
      />
      <path
        d="M70.4362 6.22339H71.9682C72.5107 6.22339 73.0213 6.54254 73.2448 7.05318L77.4894 17.234L81.9256 7.05318C82.149 6.54254 82.6277 6.22339 83.1703 6.22339H84.7022L87.6065 23.5532H84.1916L82.6596 12.8936L78.0001 23.5532H76.8511L72.415 12.8936L70.6596 23.5532H67.2448L70.4362 6.22339Z"
        fill="white"
      />
      <path
        d="M105.734 14.9043C105.734 20.1702 101.936 23.9362 96.4788 23.9362C91.0214 23.9362 87.2235 20.1702 87.2235 14.9043C87.2235 9.63833 91.0214 5.84045 96.4788 5.84045C101.968 5.84045 105.734 9.63833 105.734 14.9043ZM102.255 14.9043C102.255 11.3936 99.8618 8.90429 96.4788 8.90429C93.0959 8.90429 90.7022 11.3936 90.7022 14.9043C90.7022 18.4149 93.0959 20.8724 96.4788 20.8724C99.8937 20.8724 102.255 18.4149 102.255 14.9043Z"
        fill="white"
      />
    </svg>
  )
}

const LogoOnLight: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 133 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M113.266 0H120V9.79788C120 9.79788 113.202 13.4362 109.5 10.117V3.76596C109.5 1.69149 111.191 0 113.266 0Z"
        fill="#D52D3C"
      />
      <path
        d="M129 0H122.266V9.79788C122.266 9.79788 129.064 13.4362 132.766 10.117V3.76596C132.766 1.69149 131.075 0 129 0Z"
        fill="#2F2D66"
      />
      <path
        d="M129 23.8723H122.266V12.3511C122.266 12.3511 127.915 15.1277 132.766 11.234V20.1064C132.766 22.1808 131.075 23.8723 129 23.8723Z"
        fill="#2F2D66"
      />
      <path
        d="M113.266 23.8723H120V12.3511C120 12.3511 114.351 15.1277 109.5 11.234V20.1064C109.5 22.1808 111.191 23.8723 113.266 23.8723Z"
        fill="#D52D3C"
      />
      <path
        d="M13.3085 4.49997C12.3511 5.07444 11.1383 4.94678 10.2766 4.21274C9.54255 3.63827 8.80851 3.38295 7.78723 3.38295C5.64894 3.38295 4.53192 4.65955 4.53192 6.12763C4.53192 6.82976 4.78723 8.01061 7.02128 8.90423L9.31915 9.82976C13.5638 11.5532 14.7447 13.9787 14.7447 16.5957C14.7447 20.9681 11.6489 23.9681 7.34043 23.9681C4.69149 23.9681 3.09574 22.9787 1.91489 21.6702C0.670213 20.2978 0.12766 18.7659 0 17.2021L0.12766 17.1702C1.7234 16.8191 3.31915 17.5851 4.18085 18.9893C4.2766 19.117 4.34043 19.2447 4.43617 19.3723C5.10638 20.2021 6.06383 20.7446 7.43617 20.7446C9.57447 20.7446 11.234 19.1808 11.234 16.8829C11.234 14.5532 9.44681 13.5638 7.91489 12.9574L5.71277 12.0319C3.82979 11.2659 1.02128 9.67018 1.02128 6.22337C1.02128 3.12763 3.44681 0.159546 7.75532 0.159546C10.2447 0.159546 11.6489 1.08508 12.383 1.72338C12.9894 2.26593 13.6596 3.0638 14.1383 4.02125L13.3085 4.49997Z"
        fill="#2F2D66"
      />
      <path
        d="M16.4361 6.22339C17.9042 6.22339 19.117 7.40424 19.117 8.90424V17.1064C19.117 18.4149 19.5638 19.2447 19.9468 19.6915C20.4255 20.2979 21.351 20.8723 22.6915 20.8723C24.0319 20.8723 24.9574 20.2659 25.4361 19.6915C25.8191 19.2447 26.2659 18.4468 26.2659 17.1064V8.90424C26.2659 7.43615 27.4468 6.22339 28.9468 6.22339H29.6489V16.8191C29.6489 18.4468 29.3936 20.1064 28.3404 21.4468C27.0957 23.1064 24.9255 23.9362 22.6915 23.9362C20.4574 23.9362 18.2872 23.0745 17.0425 21.4468C15.9893 20.1383 15.734 18.4468 15.734 16.8191V6.22339H16.4361Z"
        fill="#2F2D66"
      />
      <path
        d="M36.3829 6.22339C38.3297 6.22339 39.6701 6.44679 40.9467 7.5319C42.351 8.77658 42.702 10.4681 42.702 11.7128C42.702 12.383 42.6063 14.2659 41.2659 15.5745C40.3084 16.5319 38.8722 17.1064 36.8616 17.1064H34.6914V20.9362C34.6914 22.3723 33.5425 23.5213 32.1063 23.5213H31.3403V6.22339H36.3829ZM34.7233 13.2766C34.7233 13.8191 35.1382 14.234 35.6808 14.234H36.0957C36.7339 14.234 37.5637 14.234 38.2978 13.6915C38.9361 13.2128 39.2233 12.4149 39.2233 11.6808C39.2233 10.7234 38.7446 10.0851 38.2659 9.70211C37.5637 9.22339 36.6701 9.15956 35.8722 9.15956H35.6488C35.1063 9.15956 34.6914 9.57445 34.6914 10.117V13.2766H34.7233Z"
        fill="#2F2D66"
      />
      <path
        d="M48.7979 6.22339C51.1916 6.22339 52.4043 6.86169 53.2022 7.5319C54.5107 8.64892 54.9256 10.117 54.9256 11.4255C54.9256 13.117 54.2554 14.5851 52.915 15.5106C52.4682 15.8298 51.8618 16.117 51.032 16.2766L56.4256 23.5851H54.7979C53.2022 23.5851 51.8299 22.3404 50.9362 21L47.8405 16.9787C47.7128 16.7553 47.4575 16.6276 47.2022 16.6276V23.5851H43.8192V6.22339H48.7979ZM47.1703 13.2447C47.1703 13.7872 47.5852 14.2021 48.1277 14.2021C48.766 14.2021 51.4469 14.1383 51.4469 11.5851C51.4469 8.99998 48.7979 8.99998 48.1596 8.99998H48.0958C47.5533 8.99998 47.1384 9.41488 47.1384 9.95743V13.2447H47.1703Z"
        fill="#2F2D66"
      />
      <path
        d="M64.3402 9.15956H60.3189V13.0213H64.7231V15.9255H60.3189V20.6489H64.3402C65.5529 20.6489 66.5423 21.6383 66.5423 22.851V23.5532H56.9359V6.22339H66.5423V6.92552C66.5104 8.1702 65.5529 9.15956 64.3402 9.15956Z"
        fill="#2F2D66"
      />
      <path
        d="M70.4362 6.22339H71.9682C72.5107 6.22339 73.0213 6.54254 73.2448 7.05318L77.4894 17.234L81.9256 7.05318C82.149 6.54254 82.6277 6.22339 83.1703 6.22339H84.7022L87.6065 23.5532H84.1916L82.6596 12.8936L78.0001 23.5532H76.8511L72.415 12.8936L70.6596 23.5532H67.2448L70.4362 6.22339Z"
        fill="#2F2D66"
      />
      <path
        d="M105.734 14.9043C105.734 20.1702 101.936 23.9362 96.4788 23.9362C91.0214 23.9362 87.2235 20.1702 87.2235 14.9043C87.2235 9.63833 91.0214 5.84045 96.4788 5.84045C101.968 5.84045 105.734 9.63833 105.734 14.9043ZM102.255 14.9043C102.255 11.3936 99.8618 8.90429 96.4788 8.90429C93.0959 8.90429 90.7022 11.3936 90.7022 14.9043C90.7022 18.4149 93.0959 20.8724 96.4788 20.8724C99.8937 20.8724 102.255 18.4149 102.255 14.9043Z"
        fill="#2F2D66"
      />
    </svg>
  )
}
