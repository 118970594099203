import React from 'react'

export interface ImageProps {
  src: string
  alt?: string
  className?: string
}

const Image: React.FC<ImageProps> = ({ src, ...otherProps }) => {
  const imageRepositoryPath = process.env.NEXT_PUBLIC_IMAGE_STORAGE_URL
  if (!imageRepositoryPath) {
    throw new Error('NEXT_PUBLIC_IMAGE_STORAGE_URL not configured')
  }
  const fullSrc = `${imageRepositoryPath}/${src}`
  return <img src={fullSrc} {...otherProps} />
}

export default Image
