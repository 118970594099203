import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'
import Row from './row'
import Spinner from './spinner'

type ShapeType = 'rounded' | 'round'
type VariantType = 'primary' | 'secondary' | 'accent'
type ButtonTypeType = 'button' | 'submit' | 'reset'
type ButtonSizeType = 'sm' | 'md' | 'lg'
type ButtonElevation = 'md'

type ButtonProps = {
  id?: string
  shape?: ShapeType
  variant?: VariantType
  type?: ButtonTypeType
  size?: ButtonSizeType
  className?: string
  onClick?: (id?: string) => void
  grow?: boolean
  elevation?: ButtonElevation
  disabled?: boolean
  pending?: boolean
}

const Button: React.FC<ButtonProps> = ({
  id,
  onClick,
  children,
  className,
  size = 'lg',
  shape = 'rounded',
  type = 'button',
  variant = 'primary',
  grow,
  elevation = 'md',
  disabled,
  pending,
}) => {
  const handleClick = () => {
    if (!disabled) onClick?.(id)
  }

  return (
    <button
      onClick={handleClick}
      className={classNameBuilder(
        'relative flex flex-row items-center justify-center border border-transparent font-bold uppercase shadow-sm active:opacity-75 disabled:cursor-not-allowed disabled:bg-gray-400',
        shapeClassesMap[shape],
        !disabled && variantClassesMap[variant],
        disabled && variantClassesDisabledMap[variant],
        sizeClassesMap[size],
        grow && 'w-full',
        ElevationClassesMap[elevation],
        className
      )}
      type={type}
      disabled={disabled}
    >
      <span className={classNameBuilder(pending && 'invisible')}>
        {children}
      </span>
      {pending && (
        <Row className="absolute inset-0 z-10 items-center justify-center">
          <Spinner className={classNameBuilder(spinnerSizeClassesMap[size])} />
        </Row>
      )}
    </button>
  )
}

export default Button

const shapeClassesMap: Record<ShapeType, string> = {
  rounded: 'rounded-md',
  round: 'rounded-full',
}

const variantClassesMap: Record<VariantType, string> = {
  primary: `text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`,
  secondary: `text-indigo-500 bg-indigo-50 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-100`,
  accent: `text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`,
}

const variantClassesDisabledMap: Record<VariantType, string> = {
  primary: `text-white bg-indigo-300 cursor-default`,
  secondary: `text-indigo-300 bg-indigo-50 cursor-default`,
  accent: `text-white bg-green-300 cursor-default`,
}

const sizeClassesMap: Record<ButtonSizeType, string> = {
  sm: 'text-2xs min-h-md px-md',
  md: 'text-xs min-h-lg px-lg',
  lg: 'text-sm leading-6 min-h-xl px-lg',
}

const ElevationClassesMap: Record<ButtonElevation, string> = {
  md: 'drop-shadow-md',
}

const spinnerSizeClassesMap: Record<ButtonSizeType, string> = {
  sm: 'w-sm h-sm',
  md: 'w-sm h-sm',
  lg: 'w-md h-md',
}
