export type requiredProps = string | number | undefined

export function required(value: requiredProps) {
  if (typeof value === 'string') {
    return Boolean(value.trim()) === false ? 'Campo obrigatório.' : null
  }
  return Boolean(value) === false ? 'Campo obrigatório.' : null
}

export default required
