import { useMutation } from 'react-query'
import loginService, {
  ParamsType,
  ResponseType,
} from 'services/authentication/login-service'
import { MutateHookType } from 'types/mutate-hook-type'

type HookType = MutateHookType<ParamsType, ResponseType>

export const useLogin: HookType = (options) => {
  const mutation = useMutation(loginService, options)
  const { data, error, isLoading, mutate: fetch } = mutation
  return { data, error, isLoading, fetch }
}
