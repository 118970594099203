import { XMarkIcon } from '@heroicons/react/24/solid'
import { useForgotPassword } from 'api-hooks/authentication/use-forget-password'
import Column from 'components/atoms/column'
import Modal from 'components/atoms/modal'
import { useToast } from 'hooks/use-toast'
import React from 'react'
import ForgotPasswordForm, {
  ForgotPasswordFormProps,
} from './forgot-password-form'

export interface ForgotPasswordModalProps {
  open?: boolean
  onClose?: () => void
  className?: string
  initialValues?: ForgotPasswordFormProps['initialValues']
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
  open,
  onClose,
  className,
  initialValues,
}) => {
  const { showError, showSuccess } = useToast()
  const { fetch: resetPassword, isLoading } = useForgotPassword({
    onError: showError,
    onSuccess: ({ message }) => {
      showSuccess({ message })
      onClose?.()
    },
  })

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="flex flex-grow sm:flex-grow-0"
    >
      <Column className="relative flex-grow sm:flex-grow-0">
        <button
          className="absolute top-xs right-xs z-10 flex h-lg w-lg items-center justify-center rounded-full text-indigo-400 transition-all hover:text-red-400 active:opacity-70 sm:-top-sm sm:-right-sm sm:bg-indigo-50 sm:hover:drop-shadow-md"
          onClick={onClose}
        >
          <XMarkIcon className="flex h-sm w-sm flex-shrink-0" />
        </button>
        <ForgotPasswordForm
          className={className}
          onSubmit={resetPassword}
          pending={isLoading}
          initialValues={initialValues}
        />
      </Column>
    </Modal>
  )
}

export default ForgotPasswordModal
