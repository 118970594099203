import { classNameBuilder } from 'helpers/class-name-builder'
import React, { Ref } from 'react'

type SpaceType = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  space?: SpaceType
  className?: string
  ref?: Ref<HTMLDivElement>
}

const Row: React.FC<RowProps> = React.forwardRef<HTMLInputElement, RowProps>(
  ({ space, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={classNameBuilder(
          'flex flex-row',
          spaceClassesMap[space],
          className
        )}
        {...props}
      ></div>
    )
  }
)

export default Row

const spaceClassesMap: Record<SpaceType, string> = {
  xs: 'space-x-xs',
  sm: 'space-x-sm',
  md: 'space-x-md',
  lg: 'space-x-lg',
  xl: 'space-x-xl',
}
