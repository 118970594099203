import FacebookLogoSVG from 'assets/svg/facebook-logo.svg'
import InstagramLogoSVG from 'assets/svg/instagram-logo.svg'
import TwitterLogoSVG from 'assets/svg/twitter-logo.svg'
import YoutubeLogoSVG from 'assets/svg/youtube-logo.svg'
import Circle from 'components/atoms/circle'
import Column from 'components/atoms/column'
import Container from 'components/atoms/container'
import Logo from 'components/atoms/logo'
import Row from 'components/atoms/row'
import Text from 'components/atoms/text'
import Link from 'next/link'
import React from 'react'

export interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <Column className="bg-indigo-500 px-xs sm:px-0">
      <Container className="flex flex-col pt-lg pb-8 sm:pt-11">
        <Logo variant="on-dark" className="h-md w-min" />
        <Text className="mt-sm" color="white">
          Supremo TV, a melhor preparação para você!
        </Text>
        <Text className="mt-sm" color="white" font="bold">
          Rua Sergipe, 959 - Funcionários
        </Text>
        <Text className="mt-xs" color="white" font="bold">
          (31) 3048-4900
        </Text>
        <Row className="mt-lg flex-wrap items-center justify-between">
          <Row className="space-x-3">
            <Link href="https://www.facebook.com/supremo.concursos">
              <a target="_blank">
                <Circle size="xl" className="bg-indigo-400">
                  <FacebookLogoSVG className="h-full w-full" />
                </Circle>
              </a>
            </Link>
            <Link href="https://twitter.com/Supremotv">
              <a target="_blank">
                <Circle
                  size="xl"
                  className="flex items-center justify-center bg-blue-400"
                >
                  <TwitterLogoSVG className="h-md w-md" />
                </Circle>
              </a>
            </Link>
            <Link href="https://www.instagram.com/supremotv/">
              <a target="_blank">
                <Circle size="xl" className="bg-indigo-400">
                  <InstagramLogoSVG />
                </Circle>
              </a>
            </Link>
            <Link href="https://www.youtube.com/channel/UCGhp97YJTiGh28le_3_IM4g">
              <a target="_blank">
                <Circle size="xl" className="bg-red-500">
                  <YoutubeLogoSVG className="h-md w-md" />
                </Circle>
              </a>
            </Link>
          </Row>
          <Text className="mt-11 md:mt-0" color="white" opacity="50">
            Supremo TV Ensino Jurídico LTDA | CNPJ: 18.540.533/0001-21 | Todos
            os direitos reservados.
          </Text>
        </Row>
      </Container>
    </Column>
  )
}

export default Footer
